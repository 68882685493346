import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TopContainer from "../components/TopContainer"
import img from "../images/stock/analytics1.jpg"

import { Section, Group, Col, CtaButton } from "../components/common"

class PrivacyPage extends React.Component {
  renderContent() {
    return (
      <div class="legal">
        <h2>S-FACTOR PRIVACY STATEMENT</h2>

        <p>
          At MacCormick Inc. d/b/a The S-Factor Co. (<strong>“S-Factor”</strong>
          , <strong>“we”</strong>, <strong>“us”</strong> or{" "}
          <strong>“our”</strong>) we are committed to protecting your privacy
          and safeguarding your personal information.
        </p>

        <p>
          The purpose of this privacy statement (“Privacy Statement”) is to
          inform you about the types of Personal Information (as defined below)
          S-Factor collects, uses and discloses. It explains how we use and
          disclose that information, the choices you have regarding such use and
          disclosure, and how you may correct that information.
        </p>

        <p>
          We are proud to demonstrate our commitment to your privacy, by
          complying with the laws and regulations under applicable privacy laws
          in Canada. As we are a national organization, this Privacy Statement
          is designed to meet the standards prescribed by the Personal
          Information Protection and Electronic Documents Act and the
          regulations thereunder (“PIPEDA”) as well as applicable provincial
          privacy legislation and regulations, including, and the Personal
          Information Protection Act (Alberta), the Personal Information
          Protection Act (British Columbia), and An Act respecting the
          protection of personal information in the private sector (Quebec).
        </p>

        <p>
          From time to time, we may make changes to this Privacy Statement. The
          Privacy Statement is current as of the “last revised” date which
          appears at the top of this page. We will treat Personal Information in
          a manner consistent with the Privacy Statement under which it was
          collected and our Privacy Policy, unless we have your consent to treat
          it differently. This Privacy Statement applies to any information we
          collect or receive about you, from any source.
        </p>

        <p>The following topics will be covered in this Privacy Statement:</p>

        <ol className="with-numbers">
          <li>To which companies does this Privacy Statement apply?</li>
          <li>What is Personal Information?</li>
          <li>How do we collect your Personal Information?</li>
          <li>Where do we store your Personal Information?</li>
          <li>How do we use your Personal Information?</li>
          <li>To whom do we provide your Personal Information?</li>
          <li>When and how do we obtain your consent?</li>
          <li>
            How do we ensure the privacy of your Personal Information when
            dealing with our affiliates and other third parties?
          </li>
          <li>
            How long will we utilize, disclose or retain your Personal
            Information?
          </li>
          <li>
            How can you review your Personal Information that we have collected,
            utilized or disclosed?
          </li>
          <li>
            How do you know that the Personal Information we have on you is
            accurate?
          </li>
          <li>
            What if the Personal Information we have on you is inaccurate?
          </li>
          <li>How fast will we respond to your written requests?</li>
          <li>
            Are there any costs to you for requesting information about your
            Personal Information or our Privacy Policy or practices?
          </li>
          <li>
            How do we know that it is really you requesting your Personal
            Information?
          </li>
          <li>
            What safeguards have we implemented to protect your Personal
            Information?
          </li>
          <li>
            How do you contact us regarding access to your Personal Information
            or our Privacy Policy and practices?
          </li>
          <li>Cookies</li>
        </ol>
        <hr />

        <h4>1. To which companies does this Privacy Statement apply?</h4>
        <p>
          This Privacy Statement applies to the collection, use and disclosure
          of Personal Information by S-Factor.
        </p>

        <h4>2. What is Personal Information?</h4>
        <p />

        <h4>3. How do we collect your Personal Information?</h4>
        <p>
          “Personal Information” is any information that is identifiable with
          you, as an individual. This information may include your name,
          company, mailing address, e-mail address, billing address, credit card
          information. In certain circumstances Personal Information may not
          include certain business contact information.
        </p>

        <h4>4. Where do we store your Personal Information?</h4>
        <p>
          We will always collect your Personal Information by fair and lawful
          means (for example, when you register with us). We may collect
          Personal Information from you directly and/or from third parties,
          where we have obtained your consent to do so or as otherwise required
          or permitted by law.
        </p>

        <h4>5. How do we use your Personal Information?</h4>
        <p>
          We will keep the Personal Information that we collect at the S-Factor
          offices or at the offices of our third party service providers, as
          applicable, including service providers in foreign jurisdictions such
          as the United States, where it will be subject to the laws of those
          jurisdictions.
        </p>

        <p>
          We identify the purposes for which we use your Personal Information at
          the time we collect such information from you and obtain your consent,
          in any case, prior to such use. We generally use your Personal
          Information for the following purposes (the “Purposes”):
        </p>

        <p>
          <ul>
            <li>to provide you with requested services from S-Factor;</li>
            <li>to manage your account with S-Factor;</li>
            <li>to process your orders from S-Factor;</li>
            <li>
              to respond to questions, comments or concerns regarding S-Factor;
            </li>
            <li>
              to provide you with information about promotions and other
              marketing initiatives;
            </li>
            <li>
              to communicate with you in respect of related products or services
              which we or our partners provide;
            </li>
            <li>
              to allow for more meaningful and useful sales and marketing
              initiatives;
            </li>
            <li>
              to collect your Personal Information in aggregate form to develop
              consumer profiles, perform sales analysis and identify marketing
              opportunities and strategies;
            </li>
            <li>
              to collect opinions, feedback and comments in regard S-Factor’s
              operations;
            </li>
            <li>to recruit for positions at S-Factor;</li>

            <li>
              to better understand our web traffic and marketing effectiveness
              through the use of third party conduct analytics such as Google
              Analytics (whose privacy policy can be found at{" "}
              <a
                href="https://www.google.com/policies/privacy/partners"
                target="_blank"
              >
                www.google.com/policies/privacy/partners
              </a>
              );
            </li>
            <li>
              to perform statistical research demographic analysis for planning
              purposes (but only on an aggregate and anonymous basis) ;
            </li>
            <li>to protect and safeguard S-Factor and its customers;</li>
            <li>to investigate legal claims;</li>
            <li>
              to administer S-Factor websites and any S-Factor software
              applications;
            </li>
            <li>
              such purposes for which S-Factor may obtain consent from time to
              time;
            </li>
            <li>
              such other uses as may be permitted or required by applicable law;
              and
            </li>
            <li>
              to provide our service providers and partners, with necessary data
              to facilitate your use of S-Factor services.{" "}
            </li>
          </ul>
        </p>

        <h4>6. To whom do we provide your Personal Information?</h4>
        <p>
          We identify to whom, and for what purposes, we disclose your Personal
          Information, at the time we collect such information from you and
          obtain your consent to such disclosure.{" "}
        </p>
        <p>
          For example, we may transfer your Personal Information to third party
          service providers with whom we have a contractual agreement that
          includes appropriate privacy standards, where such third parties are
          assisting us with the Purposes – such as service providers that
          provide telephone support or data storage or processing.
        </p>
        <p>
          Generally, we will only make disclosures of Personal Information to
          such persons for which you provide your consent. Notwithstanding the
          foregoing, we may also make disclosures of Personal Information to a
          potential acquiror in connection with a transaction involving the sale
          of some or all of the business of S-Factor (in which case the use of
          your personal information by the new entity would continue to be
          limited by applicable law), or as otherwise permitted or required by
          law.
        </p>
        <p>
          In addition, we may send Personal Information outside of the country
          for the Purposes, including for processing and storage by service
          providers in connection with such Purposes. However, you should note
          that to the extent that any Personal Information is out of the
          country, it is subject to the laws of the country in which it is held,
          and may be subject to disclosure to the governments, courts or law
          enforcement or regulatory agencies of such other country, pursuant to
          the laws of such country.
        </p>

        <h4>7. When and how do we obtain your consent?</h4>
        <p>
          We generally obtain your consent prior to collecting, and in any case,
          prior to using or disclosing your Personal Information for any
          purpose. You may provide your consent to us either orally,
          electronically or in writing. The form of consent that we seek,
          including whether it is express or implied, will largely depend on the
          sensitivity of the Personal Information and the reasonable
          expectations you might have in the circumstances.
        </p>

        <h4>
          8. How do we ensure the privacy of your Personal Information when
          dealing with our affiliates and other third parties?
        </h4>
        <p>
          We ensure that all affiliates and other third parties that are engaged
          to perform services on our behalf and are provided with Personal
          Information are contractually required to observe the intent of this
          Privacy Statement and our Privacy Policy and practices.{" "}
        </p>

        <h4>
          9. How long will we utilize, disclose or retain your Personal
          Information?
        </h4>
        <p>
          We may keep a record of your Personal Information, correspondence or
          comments, in a file specific to you. We will utilize, disclose or
          retain your Personal Information for as long as necessary to fulfill
          the purposes for which that Personal Information was collected and as
          permitted or required by law.{" "}
        </p>

        <h4>
          10. How can you review your Personal Information that we have
          collected, utilized or disclosed?
        </h4>
        <p>
          If you make a written request to review any Personal Information about
          you that we have collected, utilized or disclosed, we will provide you
          with any such Personal Information to the extent required by law. We
          will make such Personal Information available to you in a form that is
          generally understandable.
        </p>

        <h4>
          11. How do you know that the Personal Information we have on you is
          accurate?
        </h4>
        <p>
          We will ensure that your Personal Information is kept as accurate,
          complete and up-to-date as possible. We will not routinely update your
          Personal Information, unless such a process is necessary. We ensure
          that you have access to and can control your Personal Information. As
          such, we expect you, from time to time, to supply us with updates to
          your Personal Information when required.
        </p>

        <h4>
          12. What if the Personal Information we have on you is inaccurate?
        </h4>
        <p>
          We ensure that you have access to, can control and amend your Personal
          Information. However, at any time, you can challenge the accuracy or
          completeness of your Personal Information in our records. If you
          successfully demonstrate that your Personal Information in our records
          is inaccurate or incomplete, we will amend the Personal Information as
          required. Where appropriate, we will transmit the amended information
          to third parties having access to your Personal Information.{" "}
        </p>

        <h4>13. How fast will we respond to your written requests?</h4>
        <p>
          We will attempt to respond to each of your written requests not later
          than thirty (30) days after receipt of such requests. We will advise
          you in writing if we cannot meet your requests within this time limit.
          You have the right to make a complaint to the federal Privacy
          Commissioner in respect of this time limit.
        </p>

        <h4>
          14. Are there any costs to you for requesting information about your
          Personal Information or our Privacy Policy or practices?
        </h4>
        <p>
          We will not charge any costs for you to access your Personal
          Information in our records or to access our Privacy Policy or
          practices without first providing you with an estimate of the
          approximate costs, if any.{" "}
        </p>

        <h4>
          15. How do we know that it is really you requesting your Personal
          Information?
        </h4>
        <p>
          We may request that you provide sufficient identification to permit
          access to the existence, use or disclosure of your Personal
          Information. Any such identifying information shall be used only for
          this purpose.
        </p>

        <h4>
          16. What safeguards have we implemented to protect your Personal
          Information?
        </h4>
        <p>
          We have implemented physical, organizational, contractual and
          technological security measures to protect your Personal Information
          from loss or theft, unauthorized access, disclosure, copying, use or
          modification. The safeguards we implement in each circumstance may
          depend on the sensitivity of the Personal Information and the
          reasonable expectations you might have for the protection of such
          Personal Information. The only employees, who are granted access to
          your Personal Information, are those with a business ‘need-to-know’ or
          whose duties reasonably require such information.{" "}
        </p>

        <h4>
          17. How do you contact us regarding access to your Personal
          Information or our Privacy Policy and practices?
        </h4>
        <p>
          All comments, questions, concerns or complaints regarding your
          Personal Information or our Privacy Policy and practices, should be
          forwarded to our Privacy Officer as follows:
        </p>

        <p>
          <strong>In writing:</strong>
          <br />
          Attn Privacy Officer
        </p>

        <p>
          <strong>Address:</strong>
          <br /> [S-Factor Inc.]
          <br />
          <a href="mailto:info@thesfactor.co">info@thesfactor.co</a>
          <br />
          401 Bay Street Suite 2702
          <br />
          Toronto, Ontario
          <br />
          M5H 2Y4
          <br />
          Phone: +1.647.930.9075
          <br />
          Toll Free: +1.877.276.4822
        </p>

        <p>
          <strong>By email:</strong>
          <br />
          <a href="mailto:info@thesfactor.co">info@thesfactor.co</a>
        </p>

        <h4>18. Cookies.</h4>
        <p>
          Our websites may pass a "cookie" (a string of information that is sent
          by a website to be resident on your system's hard drive, and/or
          temporarily in your computer's memory blocks). You may set your
          browser to decline cookies. If you do so, however, you may not be able
          to fully experience some features of our websites.
        </p>
      </div>
    )
  }

  render() {
    return (
      <>
        <Layout>
          <SEO title="Analytics" />
          <TopContainer
            titleLines={["Privacy Policy"]}
            backgroundImage={img}
            backgroundPosition="center top"
          />
          <Section hasDivider={false}>
            <Group>
              <Col size="whole">{this.renderContent()}</Col>
            </Group>
          </Section>
        </Layout>
      </>
    )
  }
}

export default PrivacyPage
